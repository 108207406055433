@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-manrope;
  scroll-behavior: smooth;
}

* {
  @apply box-border;
}

@layer base {
  @font-face {
    font-family: "Manrope";
    font-weight: 300;
    src: url(/fonts/ManropeWoff2/Manrope-Light.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Manrope";
    font-weight: 400;
    src: url(/fonts/ManropeWoff2/Manrope-Regular.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Manrope";
    font-weight: 500;
    src: url(/fonts/ManropeWoff2/Manrope-Medium.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Manrope";
    font-weight: 600;
    src: url(/fonts/ManropeWoff2/Manrope-SemiBold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Manrope";
    font-weight: 700;
    src: url(/fonts/ManropeWoff2/Manrope-Bold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Manrope";
    font-weight: 800;
    src: url(/fonts/ManropeWoff2/Manrope-ExtraBold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url(/fonts/Poppins/Poppins-Regular.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    src: url(/fonts/Poppins/Poppins-Medium.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 600;
    src: url(/fonts/Poppins/Poppins-SemiBold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }

  @font-face {
    font-family: "OpenSans";
    font-weight: 400;
    src: url(/fonts/OpenSans/OpenSans-Regular.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
    unicode-range: U+0000 to U+007F;
  }
}

.theme-first {
  --colors-brand-color-primary: #0076aa;
  --colors-brand-color-secondary: #ed7823;
  --colors-orange: #fc8919;
  --colors-primary: #00bcc0;
  --colors-brand-black: #343738;
  --colors-brand-black-2: #0f0f2d;
  --colors-brand-white: #ddeef5;
  --colors-brand-gray: #979797;
  --colors-brand-blue: #40c2cd;
  --colors-brand-blue-2: #1d94c8;
  --colors-brand-blue-3: #81dad5;
  --colors-brand-gray-2: #9b9b9c;
  --colors-brand-gray-3: #f2eeee;
  --colors-brand-gray-4: #939495;
  --colors-brand-gray-5: #eef6fa;
  --colors-brand-gray-6: #c5cdd2;
  --colors-brand-gray-7: #f0f6f8;
  --colors-brand-gray-8: #d8d8d8;
  --colors-brand-gray-9: #999b9b;
  --colors-brand-orange-2: #fe4a01;
  --colors-detail-border-color: #f9f9f9;
  --colors-villa-item2: rgb(21 19 19 / 35%);
  --colors-heart-bg: #c9d0d6;
  --colors-detail-border: #e4e9eb;
  --colors-calendar_option: rgba(255, 255, 0, 0.471);
  --colors-calendar_lastMinute: rgb(139 255 194);
  --colors-calendar_notAvailable: rgba(255, 0, 0, 0.737);
  --colors-header-user-right: #0076aa;
  --bg-header-bg: linear-gradient(90deg, #ffffff 50%, #0076aa 50%);
  --colors-header-bg-white: #fff;
  --colors-product-type-color: #00bbc0;
  --colors-information-color: #152949;
  --colors-information-color2: #0176ab;
  --colors-teklif-1-color: linear-gradient(
    90deg,
    rgba(6, 132, 204, 1) 0%,
    rgba(4, 127, 191, 1) 100%
  );
  --colors-teklif-gra-color: linear-gradient(
    90deg,
    rgba(4, 127, 191, 1) 0%,
    rgba(0, 118, 170, 1) 100%
  );
  --colors-call-color: #fc8919;
  --bg-shortTerm-color: #e8eff4;
  --bgColor-faq-bg: linear-gradient(223deg, #0076aa, #0788d4);
  --bg-listSearch: #02638e;
  --colors-pagination-color: #5bc2d9;
  --priceTable-bg: linear-gradient(270deg, #f0f6f8 0%, #e2ebee 100%);
  --bg-detail-bg: linear-gradient(223deg, #339ccb 0%, #0788d4 100%);
  --bg-descriptionBg: #eff5f7;
  --bg-CheckInColors: #0ec5c9;
  --bg-info: #14c8cc;
  --color-rezervation-form: #ec7823;
  --color-securePayment1: #65accc;
  --color-securePayment2: #01bbc0;
  --getOfferBg: #87d8d4;
  --getOfferBgprevNext: #87d8d4;
  --bg-kisasureli-count-bg: linear-gradient(-41deg, #ff3900, #ff7000);
}
/* #d84d64 */
.theme-second {
  --colors-brand-color-primary: #d84d64;
  --colors-brand-color-secondary: #e81f41;
  --colors-orange: #d84d64;
  --colors-primary: #d84d64;
  --colors-brand-black: #343738;
  --colors-brand-black-2: #0f0f2d;
  --colors-brand-white: #fedddd;
  --colors-brand-gray: #979797;
  --colors-brand-blue: #d84d64;
  --colors-brand-blue-2: #e15e74;
  --colors-brand-blue-3: #81dad5;
  --colors-brand-gray-2: #9b9b9c;
  --colors-brand-gray-3: #f2eeee;
  --colors-brand-gray-4: #939495;
  --colors-brand-gray-5: #f9e2e6;
  --colors-brand-gray-6: #c5cdd2;
  --colors-brand-gray-7: #ffefef;
  --colors-brand-gray-8: #d8d8d8;
  --colors-brand-gray-9: #999b9b;
  --colors-brand-orange-2: #fe4a01;
  --colors-detail-border-color: #f9f9f9;
  --colors-villa-item2: rgb(21 19 19 / 35%);
  --colors-heart-bg: #c9d0d6;
  --colors-detail-border: #e4e9eb;
  --colors-calendar_option: rgba(255, 255, 0, 0.471);
  --colors-calendar_lastMinute: rgb(139 255 194);
  --colors-calendar_notAvailable: rgba(255, 0, 0, 0.737);
  --colors-header-user-right: #d84d64;
  --colors-header-bg-white: #fedddd;
  --bg-header-bg: linear-gradient(90deg, #fedddd 50%, #d84d64 0);
  --colors-product-type-color: #d84d64;
  --colors-information-color: #221a52;
  --colors-information-color2: #d84d64;
  --colors-teklif-1-color: linear-gradient(90deg, #e81f41 0%, #e81f41 100%);
  --colors-teklif-gra-color: linear-gradient(90deg, #e81f41 0%, #d84d64 100%);
  --colors-call-color: #e81f41;
  --bg-shortTerm-color: #ffefef;
  --bgColor-faq-bg: linear-gradient(223deg, #e81f41, #d84d64);
  --bg-listSearch: #fddddd;
  --colors-pagination-color: #f15630;
  --priceTable-bg: #ffe2e2;
  --bg-detail-bg: linear-gradient(223deg, #d84d64 0%, #d84d64 100%);
  --bg-descriptionBg: #ffebea;
  --bg-CheckInColors: #e15e74;
  --bg-info: #535050;
  --bg-info: rgb(249 249 249 / 16%);
  --color-rezervation-form: #d84d64;
  --color-securePayment1: #ef5127;
  --color-securePayment2: #ef5127;
  --getOfferBg: #ffcbc1;
  --getOfferBgprevNext: #ef5127;
  --bg-kisasureli-count-bg: linear-gradient(-41deg, #e81f41, #d84d64);
}

.container {
  @apply max-w-full;
  width: 1383px;
}

.field-border::after {
  @apply w-full h-0 bg-transparent absolute bottom-0 left-0;
  content: "";
  transition: all 0.1s linear;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@screen lg {
  .field-border:hover::after {
    @apply h-1;
    background: theme("colors.primary");
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.DatePickerFullWidth .rdrMonth {
  width: 100%;
}

/*
.DatePickerFullWidth .rdrDayToday ~ .rdrDayDisabled {
  background: red;
}

.DatePickerFullWidth .rdrDayToday ~ .rdrDayDisabled .rdrDayNumber span {
  color: white;
}
*/
.bg-villa-gradiend {
  background: #191e39;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(25, 30, 57, 0)),
    to(#1a1a35)
  );
  background: linear-gradient(180deg, rgba(25, 30, 57, 0) 0%, #1a1a35 100%);
}

.theme-first .guest-protection-gradiend {
  background: #1990d0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(33%, #1990d0),
    color-stop(33%, #75c1d5)
  );
  background: linear-gradient(90deg, #1990d0 33%, #75c1d5 33%);
}
.theme-second .guest-protection-gradiend {
  background: #1990d0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(33%, #1990d0),
    color-stop(33%, #75c1d5)
  );
  background: linear-gradient(90deg, #fedddd 33%, #fedddd 33%);
}

.guest-adam-image {
  background-image: url(../public/images/adam.jpeg);
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 589px;
  height: 100%;
}

.slick-dots {
  display: flex !important;
  justify-content: center !important;
  gap: 9px;
  margin-top: 20px;
}

.slick-dots li.slick-active > div {
  border: 0px;
  background-color: #4fb2ba;
}

.swiperPadding {
  padding-bottom: 10px !important;
}

.swiperPadding .swiper-pagination {
  bottom: -5px !important;
}

.availability-calendar .rdrDayNumber {
  top: 0.5px !important;
  bottom: 0.5px !important;
  right: 0.5px !important;
  left: 0.5px !important;
}

.availability-calendar.full-calendar .rdrDay {
  height: 4em;
}

.availability-calendar .rdrDay .doluGiris {
  background-image: linear-gradient(
    to bottom right,
    transparent 50%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .doluCikis {
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .doluGunler {
  background: rgba(255, 0, 0, 0.737);
}

.availability-calendar .rdrDay .doluGirdiCikti {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .doluGirdiCikti::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

/* 
doluCiktiOdemeGirdi */

.availability-calendar .rdrDay .doluCiktiOdemeGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .doluCiktiOdemeGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .odemeCiktiDoluGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .odemeCiktiDoluGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .odemeGiris {
  background-image: linear-gradient(
    to bottom right,
    transparent 50%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .odemeCikis {
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .odemeGunler {
  background: rgba(255, 255, 0, 0.471);
}

.availability-calendar .rdrDay .sondakikaGunler {
  background: rgb(139 255 194);
}

.availability-calendar .rdrDay .sondakikaCikis {
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .sondakikaGiris {
  background-image: linear-gradient(
    to bottom right,
    transparent 50%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .sondakikaGirdiCikti {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .sondakikaGirdiCikti::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .doluCiktiSondakikaGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .doluCiktiSondakikaGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .odemeCiktiSondakikaGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .odemeCiktiSondakikaGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .sondakikaCiktiDoluGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgba(255, 0, 0, 0.737) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .sondakikaCiktiDoluGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar .rdrDay .sondakikaCiktiOdemeGirdi {
  background-image: linear-gradient(
    to bottom right,
    transparent 52%,
    rgba(255, 255, 0, 0.471) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
}

.availability-calendar .rdrDay .sondakikaCiktiOdemeGirdi::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(
    to top left,
    transparent 52%,
    rgb(139 255 194) 0
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
}

.availability-calendar.full-calendar .rdrMonths {
  flex-wrap: wrap;
}

.availability-calendar.full-calendar .rdrMonths .rdrMonth {
  width: 100%;
}

.availability-calendar.full-calendar .rdrCalendarWrapper {
  width: 100%;
}

.availability-calendar.full-calendar.rdrCalendarWrapper {
  width: 100%;
}

.availability-calendar.full-calendar .rdrDayToday .rdrDayNumber span:after {
  display: none;
}

@screen sm {
  .availability-calendar.full-calendar .rdrMonths .rdrMonth {
    width: 50%;
  }
}

@screen lg {
  .availability-calendar.full-calendar .rdrMonths .rdrMonth {
    width: 33.33%;
  }

  .bg-get-offer-pst {
    background-position: -85px 135px;
  }
}

.rct-node-icon {
  padding: 0 !important;
}

.skiptranslate > span {
  display: none;
}

.skiptranslate {
  height: 24px;
  overflow: hidden;
  width: 90px;
}

.goog-te-combo {
  width: 90px;
}

.goog-te-gadget {
  text-align: revert;
  margin-left: auto;
  margin-bottom: -22px;
  margin-top: 8px;
  position: relative;
  z-index: 999999;
}

@screen lg {
  .goog-te-gadget {
    margin-top: 0px;
  }
  .theme-second .bOrderx {
    border-right: 1px solid #ffcbc1;
  }
}

.theme-second .iconsImgFilter {
  filter: brightness(0) saturate(100%) invert(31%) sepia(80%) saturate(1141%)
    hue-rotate(316deg) brightness(104%) contrast(78%);
}
.theme-first .iconsImgFilter {
  filter: brightness(0) saturate(100%) invert(79%) sepia(50%) saturate(6000%)
    hue-rotate(348deg) brightness(100%) contrast(98%);
}

.theme-first .listIcons {
  filter: brightness(0) saturate(100%) invert(70%) sepia(95%) saturate(384%)
    hue-rotate(140deg) brightness(88%) contrast(81%);
}
.theme-second .listIcons {
  filter: brightness(0) saturate(100%) invert(41%) sepia(36%) saturate(6590%)
    hue-rotate(349deg) brightness(99%) contrast(90%);
}

.availability-calendar.full-calendar .rdrMonthAndYearPickers {
  display: none;
}
.cky-revisit-bottom-left {
  bottom: 75px !important;
}

.banner-content img {
  max-height: 50vh;
  width: auto !important;
}
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}
