.ListVilla .slick-list {
  @apply overflow-visible;
}
.ListVilla .slick-slide:not(.slick-active) {
  @apply opacity-10;
}
.ListVilla .slick-slide {
  @apply transition-all duration-500;
}

.ListVilla .slick-list {
  margin: 0 -0.75rem;
}
.ListVilla .ListVillaBg {
  @apply bg-white;
}
.theme-first .ListVilla:nth-child(even) .ListVillaBg {
  @apply bg-brand-gray-7;
}
.theme-second .ListVilla:nth-child(even) .ListVillaBg {
  @apply bg-[#f6f6f6];
}
