.Header:before {
  @apply hidden;
}

.Wrapper {
  @apply flex h-24 content-between;
}

/* .Header.ProductDetail {
  background: #ecf2f4;
}

.Header.ProductDetail .Wrapper {
  @apply h-auto pb-4 pt-2.5;
} */

.header-menu-height {
  height: calc(100vh - theme("spacing.24"));
}

.Header nav a {
  @apply font-bold text-tiny text-brand-black px-1 2xl:px-3 rounded-md hover:bg-brand-color-primary hover:text-white transition-all;
}

@screen lg {
  .Header:before {
    @apply h-full w-full absolute left-0 block;
    content: "";
    z-index: -1;
    background: var(--bg-header-bg);
  }
  

  /* .Header.ProductDetail {
    background: white;
  }

  .Header.ProductDetail .Wrapper {
    @apply flex h-24 content-between p-0;
  } */
}